@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Tilt+Warp&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bungee&family=Inter:wght@100..900&family=Tilt+Warp&display=swap");
html {
  background-color: #f8f8f7;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif; /* Use the imported font */
}
